import baseUrl from "./baseUrl";

export const fetchOrders = async () => {
  try {
    const res = await baseUrl.post("/admin/fetchOrders");
    console.log(res.data);
    return res.data.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const fetchVirtualOrders = async () => {
  try {
    const res = await baseUrl.post("/getAllVirtualCodePayment");
    console.log(res.data);
    return res.data.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const addHome = async (data: any) => {
  try {
    const res = await baseUrl.post("/addNewHome", data);
    console.log(res.data);
    return res.data.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const signUpUser = async (data: any) => {
  try {
    const res = await baseUrl.post("/signUpUser", data);
    console.log(res.data);
    return res.data.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const addSampleOTP = async (phoneNumber: string, otp: string) => {
  try {
    const res = await baseUrl.post("/admin/addSampleOTP", {
      phoneNumber,
      otp,
      canBeDeleted: false,
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const addDevice = async (data: any) => {
  try {
    const res = await baseUrl.post("/igloo/addDevice", data);
    console.log(res.data);
    return res.data.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const getQrCodes = async (numberOfQrCodes: number) => {
  try {
    const res = await baseUrl.post("/genrateQRCodes", {
      numberOfQrCodes,
    });
    console.log("getQrCodes", res.data);
    return res.data.data[0];
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};

export const linkQrCodeWithHome = async (
  qrCodeId: string,
  homeId: string,
  linkedBy: string
) => {
  try {
    const res = await baseUrl.post("/linkQRCodeWithHome", {
      qrCodeId,
      homeId,
      linkedBy,
    });
    console.log("linkQrCodeWithHome", res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const getQrDetail = async (qrid: string) => {
  try {
    const res = await baseUrl.post("/admin/getQRCodeDataByCode", {
      code: qrid,
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const AddAgentApi = async (
  name: string,
  agentCode: string,
  email: string,
  password: string,
  address: string,
  phnNo: string,
  agentType: string
) => {
  try {
    const res = await baseUrl.post("/addAgent", {
      name,
      agentCode,
      email,
      password,
      address,
      phnNo,
      agentType,
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const EditAgentApi = async (
  _id: string,
  name: string,
  agentCode: string,
  email: string,
  password: string,
  address: string,
  phnNo: string,
  agentType: string
) => {
  try {
    const res = await baseUrl.post("/editAgent", {
      _id,
      name,
      agentCode,
      email,
      password,
      address,
      phnNo,
      agentType,
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const deleteAgentApi = async (_id: string) => {
  try {
    const res = await baseUrl.post("/deleteAgent", {
      _id,
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};

export const deactivateQr = async (qrid: string) => {
  try {
    const res = await baseUrl.post("/admin/deactivateQRCode", {
      shortId: qrid,
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};

export const changeSubscriptionByQrApi = async (data:any) => {
  try {
    const res = await baseUrl.post("/changeQRCodePlan ", 
      data
    );
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const getAllPlans = async () => {
  try {
    const res = await baseUrl.get("/getAllPlans");
    console.log("getAllPlans", res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
