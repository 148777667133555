import moment from "moment";
export const COLUMNS = [
  {
    Header: "name",
    accessor: "name",
    Cell: ({ value }) => {
      return value.substring(0, 12);
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "phoneNumber",
    accessor: "phoneNumber/email",
    Cell: ({ row }) => {
      return row.original.phoneNumber
        ? row.original.phoneNumber?.substring(0, 50)
        : row.original.email;
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "createdAt",
    accessor: "joinTimeStamp",
    Cell: ({ value }) => {
      return moment(value).format("DD-MM-YYYY hh:mm A");
    },
  },
  {
    Header: "QR Code",
    accessor: "homes",
    Cell: ({ value }) => {
      const res = [];
      value.forEach((home) => {
        home.qrCodes.forEach((qrcode) => {
          res.push(qrcode.isActivated);
        });
      });
      return res.length ? String(res) : "home not found";
    },
  },
  {
    Header: "Subscription",
    Cell: ({ row }) => {
      console.log('row',row);
      const res = [];
      row.original.homes.forEach((home) => {
        home.qrCodes.forEach((qrcode) => {
          res.push(qrcode?.currentSubscriptionPlan?.subscriptionUniqueName+'\n');
        });
      });
      return res.length ? String(res) : "home not found";
    },
  },
  {
    Header: "ProductType",
    id: 2,
    accessor: "homes",
    Cell: ({ value }) => {
      const res = [];
      value.forEach((home) => {
        res.push(home.homeType.split("/")[0]);
      });
      return res.length ? String(res) : "home not found";
    },
  },
];
