import React, { Component } from "react";
import { QRCodeSVG } from "qrcode.react";
import { ReactComponent as DoorViLogo } from "../assets/img/doorvi.svg";
import { ReactComponent as ScanToCall } from "../assets/img/scantocall.svg";
import { ReactComponent as VehicleBorder } from "../assets/img/ract_border.svg";
import { ReactComponent as BorderBottom } from "../assets/img/border_bottom.svg";
import { ReactComponent as Ring } from "../assets/img/call.svg";
import { useTranslation } from "react-i18next";
import { getCountryCallingCode } from "libphonenumber-js";

interface QRGenratorProps {
  value: string;
  index: number;
  qr: any;
  qrType: string;
}

function QRGenrator(props: any) {
  const { t } = useTranslation();
  // console.log('t',t);
  console.log(props.countries[0]);

  return (
    <>
      {props.qrType === "home" ? (
        <div className="qr-wrap">
          <p className="qr-id">
            {props.countries[0] === "all"
              ? `No ${props.qr.shortId}`
              : `No ${getCountryCallingCode(props.countries[0])}-${
                  props.countries.length
                }-${props.qr.shortId}`}
          </p>
          <p className="qr-val">
            <Ring
              style={{
                width: 22,
                height: 20,
              }}
            />
          </p>

          <QRCodeSVG
            size={182}
            style={{
              margin: "5px 0",
            }}
            className="qrcode"
            value={props.value}
          />
          <div className="qr-footer">
            <p>{t("Scan_QR_Code")}</p>
            <p>{t("To_Contact_inside")}</p>
            <BorderBottom
              style={{
                marginTop: 3,
              }}
            />
          </div>
          <ul className="qr-notes">
            <li>{t("Please_Do_Not_Knock_or_Ring_The_Bell")}</li>
            <li>{t("Scan_Camera")}</li>
          </ul>
          {/* <div className="footer-logo_wrap"> */}
          <DoorViLogo className="footer-logo" />
        </div>
      ) : (
        <div className="qr-wrap-vehicle">
          {/* <img src={VehicleBorderPng} className="borderImg" /> */}
          {/* <VehicleBorder className="borderImg" /> */}

          <p className="qr-id" style={{ right: 15, bottom: 5 }}>
            {props.countries[0] === "all"
              ? `No 0-${props.qr.shortId}`
              : `No ${getCountryCallingCode(props.countries[0])}-${
                  props.countries.length
                }${props.qr.shortId}`}
          </p>
          <QRCodeSVG
            size={120}
            style={{
              margin: "5px 0",
            }}
            className="qrcode"
            value={props.value}
          />

          <div className="qr-note-vehicle">
            <p>{t("IN_CASE_OF")}</p>
            <p>{t("WRONGLY_PARKED_EMERGENCY")}</p>
            <BorderBottom
              style={{
                marginTop: 3,
              }}
            />
          </div>
          <div className="qr-footer">
            <p>{t("Scan_QR_Code")}</p>
            <p>{t("To_Contact_Owner")}</p>
          </div>
          <DoorViLogo className="footer-logo" style={{ bottom: 5 }} />
        </div>
      )}
    </>
  );
}

export default QRGenrator;
