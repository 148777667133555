import React, { Component } from "react";
import QRHandler from "./pages/QRHandler";
//import Dashboard from "./components/Dashboard"

import { BrowserRouter, Route } from "react-router-dom";
import Auth from "./pages/Auth";
import DashBoard from "./pages/DashBoard";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducers from "./reducers";
import { Toaster } from "react-hot-toast";
import CustomQr from "./pages/customSticker/CustomQr";
import QR_Bell from "./components/qrtypes/QR_Bell";
import QRBellGenerator from "./components/qrtypes/QRBellGenerator";

const store = createStore(reducers);
export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Toaster />
        <BrowserRouter>
          <Route exact path={"/"} component={Auth} />
          <Route exact path={"/qrs"} component={QRHandler} />
          <Route exact path={"/dashboard"} component={DashBoard} />
          <Route exact path={"/customqr"} component={QRBellGenerator} />
        </BrowserRouter>
      </Provider>
    );
  }
}
