import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDetail(props: any) {
  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  console.log("row", props.row);

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <h3
            style={{
              margin: "10px 0",
            }}
          >
            {props.row.original._id} Profile
          </h3>
          <p>Name : {props.row.original.name}</p>
          <p>
            phone Number/email :{" "}
            {props.row.original.phoneNumber ??
              props.row.original.email ??
              props.row.original.phoneNo}
          </p>
        </div>
        {props.row?.original?.homes?.length>0 && props.row?.original?.homes?.map((home: any, i: number) => {
          return (
            <div
              key={home._id}
              style={{
                padding: 10,
                borderTop: "2px solid lightgrey",
                borderBottom: "2px solid lightgrey",
              }}
            >
              <h3>Address {i + 1}</h3>
              <p>Home Type : {home?.homeType}</p>
              <p>Short Address : {home?.homeTitle}</p>
              <p>Full Address : {home?.fullAddress}</p>
              <p>
                home createdAt :{" "}
                {moment(new Date(home.createdAt)).format("DD-MM-YYYY hh:mm A")}
              </p>
              <p>
                home updatedAt :{" "}
                {moment(new Date(home.updatedAt)).format("DD-MM-YYYY hh:mm A")}
              </p>
              {home?.qrCodes.map((qr: any, i: number) => {
                return (
                  <div key={i}>
                    <h3>QR Code {i + 1}</h3>

                    <p>QR Code Activated : {String(qr.isActivated)}</p>
                    <p>Qr id : {qr._id}</p>
                    <p>
                      Qr subscription :
                      {home.qrCodes.map((qr: any, i: number) => (
                        <span>
                          {qr?.currentSubscriptionPlan?.subscriptionUniqueName}
                        </span>
                      ))}
                    </p>
                    <p>QR shortId : {qr.shortId}</p>
                    <p>
                      linkedOn :{" "}
                      {moment(new Date(qr.linkedOn)).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </p>
                    <p>
                      createdAt :{" "}
                      {moment(new Date(qr.createdAt)).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </p>
                    <p>
                      updatedAt :{" "}
                      {moment(new Date(qr.updatedAt)).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
